<template>
  <div class="menu-form-editor">
    <div v-if="menu != null">
      <div class="form-title">
        <div class="menu-name">
          {{ menu.name }}
        </div>
        <div class="delete-btn">
          <el-button type="text" @click="deleteCurrentMenu">删除菜单</el-button>
        </div>
      </div>

      <div class="form-div">
        <el-form
          class="editor-form"
          size="mini"
          label-width="130px"
          label-suffix=":"
        >
          <el-form-item label="菜单名称" style="width: 500px">
            <el-input v-model="menuData.name" />
            <span class="form-item-notice" >仅支持中英文和数字,字数不超过4个汉字或8个字母</span>
          </el-form-item>
          <el-form-item v-if="!this.menu.sub_button || this.menu.sub_button.length === 0" label="菜单内容">
            <el-select v-model="menuData.type">
              <el-option value="click" label="点击事件" />
              <el-option value="view" label="打开网页" />
              <el-option value="miniprogram" label="小程序" />
              <!-- <el-option value="scancode_push" label="微信扫码(自动解析)" />
              <el-option value="scancode_waitmsg" label="微信扫码(等待自定义解析)" />
              <el-option value="pic_sysphoto" label="拍照上传" />
              <el-option value="pic_photo_or_album" label="拍照或相册上传" />
              <el-option value="pic_weixin" label="相册上传照片" />
              <el-option value="location_select" label="选择位置" /> -->
              <el-option value="media_id" label="下发非图文素材" />
              <el-option value="article_id" label="下发图文素材" />
              <el-option value="article_view_limited" label="跳转到图文" />
            </el-select>
          </el-form-item>

          <!-- 点击事件，事件处理器从服务端获取 -->
          <el-form-item v-if="menuData.type === 'click'" label="事件处理器">
            <el-radio-group v-model="menuData.handlerId">
              <el-radio
                v-for="item in eventHandlerList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <!-- 打开网页 或 小程序 -->
          <el-form-item
            v-if="menuData.type === 'view' || menuData.type === 'miniprogram'" label="网页链接" >
            <el-input type="text" v-model="menuData.url" />
            <span v-if="menuData.type === 'miniprogram'" class="form-item-notice" >低版本微信客户端打开替换网页链接</span>
          </el-form-item>

          <!-- 小程序 -->
          <el-form-item v-if="menuData.type === 'miniprogram'" label="小程序APPID" >
            <el-input type="text" v-model="menuData.appid" />
            <span class="form-item-notice"> 已关联小程序的APPID </span>
          </el-form-item>
          <el-form-item v-if="menuData.type === 'miniprogram'" label="小程序路径" >
            <el-input type="text" v-model="menuData.pagepath" />
            <span class="form-item-notice"> 将要跳转的小程序路径 </span>
          </el-form-item>

          <!-- 下发非图文消息 -->
          <el-form-item
            v-if="menuData.type === 'media_id'" label="非图文素材ID">
            <el-input type="text" v-model="menuData.mediaId" />
          </el-form-item>

          <!-- 下发或跳转到图文素材 -->
          <el-form-item
              v-if="menuData.type === 'article_view_limited' || menuData.type === 'article_id' " label="图文素材" >
              <el-input type="text" v-model="menuData.articleId" />
          </el-form-item>
        </el-form>
      </div>

      <div class="form-btns">
        <el-button size="mini" type="success" @click="doSave">确认</el-button>
      </div>
    </div>
    <div v-else class="empty-div">
      <el-empty description="点击左侧菜单开始编辑"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-editor",
  props: {
    menu: {
      type: Object
    },
  },
  data() {
    return {
      menuData: {},
      eventHandlerList: [
        {
          id: 1,
          name: "获取用户标识",
        },
        {
          id: 2,
          name: '发送关注通知'
        },
        {
          id: 'meituan',
          name: '美团外卖'
        }
      ],
    };
  },
  methods: {
    deleteCurrentMenu() {
      let _this = this;
      this.$confirm(
        `确定要删除这个菜单吗？`,
        "提示",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          async beforeClose(action, instance, done) {
            if (action === "confirm") {
              _this.$emit('delete-menu', _this.menu);
            }
            done();
          },
        });
    },
    doSave() {
      this.$emit('save-menu', this.menu, this.menuData);
    }
  },
  watch: {
    menu: {
      immediate: true,
      handler(newVal) {
        this.menuData = { ...newVal };
      },
    },
  },
};
</script>

<style lang="less">
.menu-form-editor {
  padding: 10px;
  padding-right: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-title {
    flex: 1;
    margin-bottom: 1rem;
    border-bottom: @hair-border-ccc;
    .menu-name {
      display: inline-block;
      height: 40px;
      line-height: 40px;
    }
    .delete-btn {
      float: right;
    }
  }

  .form-div {
    flex: 12;

    .editor-form {
      .el-form-item {
        margin-bottom: 5px;

        .form-item-notice {
          font-size: 12px;
          color: #9a9a9a;
        }
      }
    }
  }

  .form-btns {
    flex: 1.5;
    text-align: center;
  }

  .empty-div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>